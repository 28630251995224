import React from 'react';
import logo from './logo.svg';
import {
  BrowserRouter as Router, Redirect, Route,
} from "react-router-dom";
import './App.css';
import Pages from './pages';
import { MainLayout } from "./components/Layout/MainLayout"
import { AuthContextProvider } from './contexts/AuthContext';

function App() {
  return (
    <Router>
      <AuthContextProvider>
        <MainLayout>
          <Pages />
        </MainLayout>
      </AuthContextProvider>
    </Router>
  );
}

export default App;
