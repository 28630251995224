import { API_PREFIX } from "../constants/api";
import { fa } from "./fetchProvider"

export async function getHouseList () {
  const res = await fa(`${API_PREFIX}/house`)
  if (res.ok) {
    return res.json()
  }
}

export async function addHouse(title) {
  const res = await fa(`${API_PREFIX}/house`, {
    method: 'PUT',
    body: {title},
  })
  if (res.ok) return res.json();
}
