import React, { useState } from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { addHouse } from '../../api/houseApi'
import { useQueryCache } from 'react-query'

export default function AddHouseForm () {
  const [value, setValue] = useState('')
  const queryCache = useQueryCache()
  const onSubmit = e => {
    e.preventDefault();
    addHouse(value).then(res => {
      queryCache.invalidateQueries('houseList')
    });
  }
  return <form className="p-field p-col p-inputgroup" onSubmit={onSubmit}>
    <span className="p-float-label">
        <InputText id="inputtext" value={value} onChange={(e) => setValue(e.target.value)} />
        <label htmlFor="inputtext">Фамилия рода</label>
    </span>
    <Button label="Добавить" />
</form>
}