import { Button, Col, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { LoginForm } from '../LoginForm'

export const LoginPage = () => {
  return <>
  <Row><Col /></Row>
  <Row justify="space-around" align="middle">
    <Col span={8}>
      <LoginForm />
    </Col>
  </Row>
  <Row justify="space-around" align="middle">
    <Col span={8}>
      <Button type="dashed" block>
        <Link to="/signup">Зарегистрироваться</Link>
      </Button>
    </Col>
  </Row>
  </>
}