import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { TreePage } from './list';
import { EditPersonPage } from './editPerson';

export default () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/:personId`}>
        <EditPersonPage />
      </Route>
      <Route path={match.path} >
        <TreePage />
      </Route>
    </Switch>
  )
}