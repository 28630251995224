import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import { getAccessToken, signIn, signOut } from '../api/authApi';
import { LoginPage } from '../components/LoginPage';
import { RegistrationPage } from '../components/RegistrationPage';
import { tokenStorage } from '../helpers/tokenStorage';

export const AuthContext = createContext();

export const AuthContextProvider = ({children}) => {
  const {status, refetch, data, error} = useQuery('refresh', getAccessToken, {
    enabled: false,
    refetchInterval: 1000 * 60 * 25,
    retry: false,
  });
  const [currentToken, setCurrentToken] = useState("");
  const [authorized, setAuthorized] = useState(false);
  const [timer, setTimer] = useState(() => null);

  useEffect(() => {
      refetch();
  }, [])

  const signOutHandler = useCallback(() => {
    async function onSignOut() {
      await signOut();
      refetch();
    }
    onSignOut();
  }, [])

  return <AuthContext.Provider value={{
    accessToken: currentToken,
    signOut: signOutHandler,
    refresh: refetch,
    status,
    }}>
    
    <Switch>
      <Route path="/signup">
        <RegistrationPage />
      </Route>
      <Route path="/signin">
        <LoginPage />
      </Route>
      <Route>
        {error && error.status === 401
          ? <LoginPage />
          : status === "success"
            ? children
            : `Status: ${status}`
        }
      </Route>
    </Switch>
  </AuthContext.Provider>
}