import React, {useCallback, useState} from 'react'
import { Upload, message, Image } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

const { Dragger } = Upload

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error('Image must smaller than 5MB!');
  }
  return isJpgOrPng && isLt2M;
}

export const UploadAvatar = ({currentImage}) => {
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState(currentImage)

  const handleChange = useCallback(info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        setImageUrl(imageUrl);
        setLoading(false);
      });
    }
  }, []);

  const {personId: id} = useParams();
  return (
    <Dragger
      name="image"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      action={`/api/family/${id}/image`}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      <div className="upload-underlay">
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
      {imageUrl ? <img src={imageUrl} alt="avatar" width="100%" className="upload-image" /> : null}
    </Dragger>
  );
}
