export const fa = (path, options = {}) => {
  return f(path, {
    ...options,
    headers: {
      ...(options.headers || {}),
    }
  })
}

export const f = async (path, options = {}) => {
  if (options.body) options.body = JSON.stringify(options.body);
  const res = await fetch(path, {
    ...options,
    headers: {
      ...(options.headers || {}),
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  })
  if (res.ok) return res;
  const e = new Error(res.statusText);
  e.status = res.status;
  throw e;
}