import React from 'react';
import { SmallCard } from '../personCard';
import { PageTitle } from '../common/PageTitle';
import { getFullName } from '../../helpers/person';
import { Button } from 'primereact/button';
import { DataView } from 'primereact/dataview';

const FamilyList = ({familyList, addPerson}) => {
  return (
    <div className="p-grid">
      <div className="p-col">
        <div className="p-grid">
          {
            familyList.map((el, i) => {
              return <div key={`person_${i}`} className="p-col-12 p-sm-12 p-md-6 p-lg-4">
              <SmallCard person={{
                description: `Дата рождения: ${el.birthDate}`,
                fullName: getFullName(el),
                ...el
                }}
              />
            </div>
            })
          }
        </div>
      </div>
    </div>
  )
}

export default FamilyList;
