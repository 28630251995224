import { API_PREFIX } from "../constants/api";
import { fa } from "./fetchProvider"

export async function getFamilyTree () {
  const res = await fa(`${API_PREFIX}/family`)
  if (res.ok) {
    return res.json()
  }
}

export async function updatePerson (id, data) {
  const res = await fa(`${API_PREFIX}/family/${id}`, {
    method: 'POST',
    body: data,
  });
  if(res.ok) return res.json();
}

export async function setRelation (id, relation, otherId) {
  const res = await fa(`${API_PREFIX}/family/${id}/relation/${relation}/${otherId}`, {
    method: 'POST',
  });
  if(res.ok) return res.json();
}

export async function setHouse (id, houseId) {
  const res = await fa(`${API_PREFIX}/family/${id}/house/${houseId}`, {
    method: 'POST',
  });
  if(res.ok) return res.json();
}

export async function addPerson() {
  const res = await fa(`${API_PREFIX}/family`, {
    method: 'PUT',
  })
  if (res.ok) return res.json();
}

export async function removePerson(id) {
  const res = await fa(`${API_PREFIX}/family/${id}`, {
    method: 'DELETE',
  })
  if (res.ok) return res.json();
}