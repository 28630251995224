import React from 'react';
import { Dropdown } from 'primereact/dropdown';

export default function HouseDropdown ({value, options, onChange}) {
  return <Dropdown
    {...{value, options, onChange} }
    optionLabel="title"
    optionValue="id"
    placeholder="Выберите род"
    style={{width: '100%'}}
    dataKey="id"
    />
}
