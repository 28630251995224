import React from 'react';
import { TopMenu } from './TopMenu';

export function MainLayout({children}) {
  return (
    <div className="">
      {/* <SideMenu /> */}
      <TopMenu />
      <div className="">
        <div className="site-layout p-p-0 p-p-sm-1 p-p-md-2 p-p-lg-3" style={{ padding: '0 50px', marginTop: 40, minHeight: '98vh' }}>
          {children}
        </div>
      </div>
    </div>
  );
}
