import React, { useState } from 'react';
import {
  Form,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  DatePicker,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useCallback } from 'react';
import { signUp } from '../../api/authApi';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { useQuery } from 'react-query';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
const config = {
  rules: [{ type: 'object', message: 'Please select time!' }],
};

export const RegistrationForm = () => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState(null);
  const {refresh} = useContext(AuthContext);
  const history = useHistory();
  const {error, status, data = {}, refetch} = useQuery(['signup', formData], (path, a) => signUp(a), {enabled: false})

  const onFinish = useCallback((values) => {
    setFormData(values); 
  }, []);

  useEffect(() => {
    if (!formData) return;
    refetch();
  }, [formData]);

  useEffect(() => {
    if (status === "success") {
      refresh();
      history.push("/");
    }
  }, [status])


  return (
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      scrollToFirstError
      autoComplete={"off"}
    >
      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input your E-mail!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject('The two passwords that you entered do not match!');
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      
      <Form.Item
        name={['firstName']}
        label="Имя"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['fathersName']}
        label="Отчество"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['secondName']}
        label="Фамилия"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name={["birthDate"]} label="Дата рождения" {...config}>
        <DatePicker format="DD.MM.YYYY" />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Зарегистрироваться
        </Button>
      </Form.Item>
    </Form>
  );
};
