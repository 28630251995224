import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, useMutation, useQueryCache } from 'react-query';
import { getFamilyTree, addPerson } from '../../api/treeApi';
import { ProgressSpinner } from 'primereact/progressspinner';
import FamilyList from '../../components/FamilyList';
import { TabView,TabPanel } from 'primereact/tabview';
import { useHouseList } from '../../hooks/useHouseList';
import { Button } from 'primereact/button';
import { useState } from 'react';
import AddHouseForm from '../../components/AddHouseForm';

export const TreePage = function () {
  const res = useQuery('familyList', getFamilyTree);
  const {isLoading, isError, data, error} = res;
  const [mutateAdd] = useMutation(addPerson);
  const queryCache = useQueryCache();
  const houseReq = useHouseList();
  const [activeHouse, setActiveHouse] = useState(0); 
  
  const history = useHistory();
  const location = useLocation();

  const addPersonCall = useCallback(() => {
    mutateAdd(null, {
      async onSuccess(person) {
        await queryCache.invalidateQueries('familyList');
        history.push(`${location.pathname}/${person.id}`);
      }
    })
  }, [location])

  if (isLoading) return <ProgressSpinner />;
  if (isError) return JSON.stringify(error);

  const activeHouseId = ((houseReq.data || [])[activeHouse - 1] || {}).id || 0
  const familyList = (data || []).filter(p => activeHouseId ? p.houseId === activeHouseId : true )

  return <>
    <div className="p-grid p-p-0 p-p-sm-1 p-p-md-2 p-p-lg-3" >
      <div className="p-col-8">
        <h1>Моя семья</h1>
      </div>
      <div className="p-col p-text-right">
        <Button onClick={addPersonCall} key="add_btn">Добавить</Button>
      </div>
    </div>
    {houseReq.isFetched && houseReq.data?.length >= 0 && <TabView activeIndex={activeHouse} onTabChange={(e) => setActiveHouse(e.index)}>
      <TabPanel header="Все" >
        <FamilyList familyList={familyList}/>
      </TabPanel>
      {houseReq.isFetched && (houseReq.data || []).map(house => <TabPanel header={house.title} key={"house_" + house.id} ><FamilyList familyList={familyList}/></TabPanel>)}
      <TabPanel header="Добавить род">
        <AddHouseForm />
      </TabPanel>
    </TabView>}
  </>
}