import React from 'react';
import { useQuery } from "react-query"
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { EditPerson } from '../../components/editPerson';
import { getFamilyTree } from '../../api/treeApi';

export const EditPersonPage = () => {
  const params = useParams();
  const {personId} = params;
  const {isLoading, isError, data, error} = useQuery('familyList', getFamilyTree);

  if (isLoading) return <Spin />;
  if (isError) return JSON.stringify(error);

  return <EditPerson person={data.find(el => el.id === parseInt(personId))} />
}