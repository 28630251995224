import React, { useCallback, useState } from 'react';
import { Descriptions, Drawer } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { useMutation, useQueryCache } from 'react-query';
import { removePerson } from '../../api/treeApi';
import { publicUrl } from '../../helpers';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { confirmPopup } from 'primereact/confirmpopup';

export const SmallCard = ({person}) => {
  const { birthDate, deathDate } = person;
  const queryCache = useQueryCache();
  const match = useRouteMatch();
  const [mutateRemove, {isLoading: isRemoveLoading}] = useMutation(removePerson);

  const removePersonCall = useCallback(() => {
    return mutateRemove(person.id, {
      onSuccess() {
        queryCache.invalidateQueries('familyList');
      }
    })
  }, [person.id, mutateRemove, queryCache])



  const confirmDelete = (event) => {
    confirmPopup({
        target: event.currentTarget,
        message: "Вы действительно хотите удалить эту карточку?",
        header: 'Подтвердите действие',
        icon: 'pi pi-exclamation-triangle',
        accept: removePersonCall,
        acceptLabel: "Да",
        rejectLabel: "Нет",
        // reject: reject
    });
  }

  const dates = birthDate && deathDate && birthDate + ' - ' + deathDate || birthDate || ""

  return (
    <div 
      className="p-m-1 p-shadow-4 p-grid p-align-stretch vertical-container"
      title={person.fullName}
      subTitle={person.description}
      style={{height: "100%"}}
    >
      <div className="p-col-fixed" style={{width: '150px'}}>
        <img
            alt={person.fullName}
            width="100%"
            src={publicUrl`/preview/c/q/150x200/${person.avatar}`}
            onError={(e) => e.target.src=publicUrl`/default.png`}
          />
      </div>
      
      <div className="p-col">
        <div className="p-text-wrap p-text-bold">{person.fullName}</div>
        <div className="p-text-nowrap">{dates}</div>
      </div>
      <div className="p-col-12 p-col-align-end">
        <Link to={`${match.url}/${person.id}`}>
          <Button icon="pi pi-user-edit" label="Редактировать" className="p-mr-2 p-mb-2 p-button-sm" />
        </Link>
        <Button label="Удалить" icon="pi pi-trash" onClick={confirmDelete} className="p-button-secondary p-mb-2 p-button-sm" />
      </div>
    </div>
  )
}