import { API_PREFIX } from "../constants/api"
import { f, fa } from "./fetchProvider"

export async function getAccessToken () {
  return f(`${API_PREFIX}/auth/refresh`);
}

export async function signIn (payload) {
  return f(`${API_PREFIX}/auth/login`, {
    method: 'POST',
    body: payload,
  });
}

export async function signUp (payload) {
  return f(`${API_PREFIX}/auth/register`, {
    method: 'POST',
    body: payload,
  });
}

export async function signOut () {
  return fa(`${API_PREFIX}/auth/logout`);
}