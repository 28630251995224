import React from 'react';
import { Dropdown } from 'primereact/dropdown';

export const RelationDropdown = ({value, options, onChange}) => {
  return <Dropdown
    {...{value, options, onChange} }
    optionLabel="fullName"
    optionValue="id"
    placeholder="Выберите"
    filter
    resetFilterOnHide={true}
    style={{width: '100%'}}
    dataKey="id"
    filterBy="fullName"/>
}
