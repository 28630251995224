import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import Family from "./family";
import Main from "./Main";

export default ({children}) => {
  return <>
    {children}
    <Switch>
      <Route path="/" exact={true}>
        <Redirect to="/family" />
      </Route>
      <Route path="/family">
        <Family />
      </Route>
    </Switch>
  </>
}