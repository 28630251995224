import React, { useCallback, useContext, useState } from "react"
import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';

import { AuthContext } from "../../../contexts/AuthContext";

export const TopMenu = () => {
  const [collapsed, setCollapsed] = useState(false);
  const authContext = useContext(AuthContext);
  const {signOut} = authContext;

  const onCollapse = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed, setCollapsed]);

  const items = [
    {
      label:'Quit',
      icon:'pi pi-fw pi-power-off',
      command: signOut,
   }
  ]

  return (
      <Menubar
        model={items}
        // end={<Button label="Logout" icon="pi pi-power-off" onClick={signOut}/>}
        />
  );
}
