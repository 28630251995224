import React from 'react';
import { Form, Input, InputNumber, Button, DatePicker } from 'antd';
import { UploadAvatar } from './uploadAvatar';
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};
const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not validate email!',
    number: '${label} is not a validate number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

const config = {
  rules: [{ type: 'object', message: 'Please select time!' }],
};

export const EditPersonForm = ({fields, onSubmit}) => {
  console.log('fields', fields);
  const onFinish = (values) => {
    Object.keys(values).forEach((key) => {
      if (key.includes('Date')) values[key] = values[key] && values[key].format('YYYY-MM-DD');
    });
    console.log(values);
    onSubmit(values);
  };

  return (
    <Form
      {...layout}
      name="edit-person"
      onFinish={onFinish}
      validateMessages={validateMessages}
      fields={fields}
      autoComplete={"off"}
    >
      <Form.Item
        name={['firstName']}
        label="Имя"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['fathersName']}
        label="Отчество"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={['secondName']}
        label="Фамилия"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Годы жизни">
        <Form.Item name={["birthDate"]} {...config} style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}>
          <DatePicker format="DD.MM.YYYY" />
        </Form.Item>
        <Form.Item name={["deathDate"]} {...config} style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}>
          <DatePicker format="DD.MM.YYYY" />
        </Form.Item>
      </Form.Item>
      {/* <Form.Item name={['info']} label="Introduction">
        <Input.TextArea />
      </Form.Item> */}
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: layout.labelCol.span }}>
        <Button type="primary" htmlType="submit">
          Сохранить
        </Button>
      </Form.Item>
    </Form>
  );
};
