import { Button, Col, Row } from 'antd'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { RegistrationForm } from '../RegistrationForm'

export const RegistrationPage = () => {
  const history = useHistory();
  return <>
  <Row><Col /></Row>
  <Row justify="space-around" align="middle">
    <Col span={8}>
      <RegistrationForm />
    </Col>
  </Row>
  <Row justify="space-around" align="middle">
    <Col span={8}>
      <Button type="dashed" block onClick={history.goBack}>
        Назад
      </Button>
    </Col>
  </Row>
  </>
}