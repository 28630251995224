import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import PrimeReact from 'primereact/api';

import * as serviceWorker from './serviceWorker';
import { ReactQueryCacheProvider, QueryCache } from 'react-query';
import App from './App';
PrimeReact.ripple = true;

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      retry: false,
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ReactQueryCacheProvider queryCache={queryCache}>
      <App />
    </ReactQueryCacheProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
