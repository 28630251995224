import React, { useState } from 'react';
import {
  Form,
  Input,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
  DatePicker,
} from 'antd';
import { useCallback } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { useQuery } from 'react-query';
import { signIn } from '../../api/authApi';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
const config = {
  rules: [{ type: 'object', message: 'Please select time!' }],
};

export const LoginForm = () => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState(null);
  const {refresh} = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const {error, status, data = {}, refetch} = useQuery(['signin', formData], (path, a) => signIn(a), {enabled: false})
  const onFinish = useCallback((values) => {
    setFormData(values); 
  }, []);

  useEffect(() => {
    if (!formData) return;
    refetch();
  }, [formData]);

  useEffect(() => {
    if (status === "success") {
      refresh();
      if (location.pathname === "/signin") {
        history.push("/")
      }
    }
  }, [status])

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="login"
      onFinish={onFinish}
      scrollToFirstError
    >
      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          // {
          //   type: 'email',
          //   message: 'The input is not valid E-mail!',
          // },
          {
            required: true,
            message: 'Please input your E-mail!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Войти
        </Button>
      </Form.Item>
    </Form>
  );
};
