import { Affix, Col, PageHeader, Row } from 'antd'
import React from 'react'


export const PageTitle = (props) => (
  <Row>
    <Col span={24}>
      <PageHeader 
        {...props}
      />
    </Col>
  </Row>
)