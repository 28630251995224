import React, { useCallback, useMemo, useState } from 'react';
import { EditPersonForm } from './EditPersonForm';
import moment from 'moment'
import { getFamilyTree, setHouse, setRelation, updatePerson } from '../../api/treeApi';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Col, Image, Row } from 'antd';
import { UploadAvatar } from './uploadAvatar';
import { publicUrl } from '../../helpers';
import { PageTitle } from '../common/PageTitle';
import { getFullName } from '../../helpers/person';
import { RelationDropdown } from './RelationDropdown';
import HouseDropdown from './HouseDropdown';
import { useHouseList } from '../../hooks/useHouseList';

/*
"firstName": null,
"secondName": null,
"fathersName": null,
"birthDate": null,
"deathDate": null
*/

function personToForm(person) {
  return Object.entries(person).map(([key, value]) => {
    if (key.includes('Date')) value = value && moment(value);
    return {
      name: [key],
      value,
    }
  })
}

export function EditPerson({person}) {
  const [fields, setFields] = useState(personToForm(person || {}));
  const [mutate] = useMutation(({id, fields}) => updatePerson(id, fields))
  const [mutateSetRelation] = useMutation((vars) => setRelation(...vars))
  const [mutateSetHouse] = useMutation((vars) => setHouse(...vars))

  const {isLoading, isError, data, error} = useQuery('familyList', getFamilyTree);
  const familyList = data.map(el => ({...el, fullName: getFullName(el)}))
  const queryCache = useQueryCache();
  const history = useHistory();
  const {data: houseList = [] } = useHouseList();

  const men = useMemo(() => {
    return familyList.filter(el => el.sex != 'female')
  }, [person, familyList])
  const women = useMemo(() => {
    return familyList.filter(el => el.sex != 'male')
  }, [person, familyList])
  const mother = useMemo(() => {
    return familyList.find(el => el.id === person.motherId) || {}
  }, [person, familyList])
  const father = useMemo(() => {
    return familyList.find(el => el.id === person.fatherId) || {}
  }, [person, familyList])

  const onSetRelation = useCallback((relationType) => {
    return ({originalEvent, value}) => {
      mutateSetRelation([person.id, relationType, value], {
        onSuccess: () => {
          queryCache.invalidateQueries('familyList');
        }
      })
    }
  }, [person.id, mutateSetRelation])

  const onSetHouse = useCallback((houseId) => {
    return ({originalEvent, value}) => {
      mutateSetHouse([person.id, value], {
        onSuccess: () => {
          queryCache.invalidateQueries('familyList');
        }
      })
    }
  }, [person.id, mutateSetHouse])

  const onSubmit = (fields) => {
    console.log('submit', fields);
    return mutate({id: person.id, fields}, {
      onSuccess: () => {
        queryCache.invalidateQueries('familyList');
        history.push('/family');
      }
    });
  };

  const onCancel = useCallback(() => {
    history.goBack();
  }, [history])

  if (!person) return 'none person';
  const currentImage = person.avatar ? publicUrl`/img/${person.avatar}` : "/default.png"
  return <>
    <PageTitle
      title={`Редактировать: ${getFullName(person)}`}
      onBack={onCancel}
    />
    <Row>
      <Col span={6}>
        <UploadAvatar currentImage={currentImage} />
      </Col>
      <Col span={18}>
        {houseList.length && (
        <div className="p-grid">
          <div className="p-col-3 p-text-right">Род:</div>
          <div className="p-col-9">
            <HouseDropdown value={person.houseId} options={houseList} onChange={(onSetHouse())} />
          </div>
        </div>
        )
        }
        <EditPersonForm fields={fields} onChange={setFields} onSubmit={onSubmit} />
        <div className="p-grid">
          <div className="p-col-3 p-text-right">Мать:</div>
          <div className="p-col-9">
            <RelationDropdown value={mother.id} options={women} onChange={onSetRelation('mother')} />
          </div>
          <div className="p-col-3 p-text-right">Отец:</div>
          <div className="p-col-9">
            <RelationDropdown value={father.id} options={men} onChange={onSetRelation('father')} />
          </div>
        </div>
      </Col>
    </Row>
  </>
}